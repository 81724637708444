import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#0590B2';
const footerRelatedLinks = [
	{ title: 'Debiopharm', url: '/en/projects/debiopharm/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/avriontx/avrion_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/avriontx/avrion_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/avriontx/avrion_brand_dna_en.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/avriontx/avrion_2.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/avriontx/avrion_3.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/avriontx/avrion_4.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/avriontx/avrion_5.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/avriontx/avrion_6.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/avriontx/avrion_7.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: { eq: "projects/avriontx/avrion_8.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: { eq: "projects/avriontx/avrion_9.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: { eq: "projects/avriontx/avrion_10.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: { eq: "projects/avriontx/avrion_11.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/avrion-therapeutics-branding/',
					lang: 'fr',
				},
			]}
			title="Avrion Therapeutics Branding - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Avrion Therapeutics"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Rebuilding lives and offering hope to people with
							neurodegenerative diseases (ESL)
						</p>

						<p className="p-small">
							Avrion Therapeutics is developing a treatment for
							amyotrophic lateral sclerosis (ALS), an incurable
							neurodegenerative disease that leads to death within
							3 to 5 years.{' '}
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Brand positioning',
								'Branding',
								'Visual identity',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							In the highly-specialized environment of biotech
							startups targeting DNA, this EPFL spin-off stands
							out from the pack for its precision and
							technological simplicity. Our branding work was
							driven by a desire to veer from the well-trodden
							path and shy away from the symbolism used by
							competitors. All while applying a minimalist visual
							approach.{' '}
						</p>

						<h3>Our solution</h3>
						<p>
							We chose to focus on the human being and their life
							stories rather than focusing directly on technology.
							Behind each person with the disease is DNA: a life
							searching for a glimmer of hope that one day they
							will be able to live a normal life again.
						</p>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>Brand DNA</h3>
							<p>
								After identifying Aviron’s core values in our
								brand workshops, we translated them into a
								strong, consistent brand with significant growth
								potential.
							</p>
						</div>
						<Image {...data.image_1.childImageSharp} alt="Logo" />
					</div>
				</div>

				<Video
					className="col-full"
					src="d979a2eeca219b93a972ddf6770e97d2"
					alt=""
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="What if the source of disease can become a source of hope and life?"
					more="Avrion Therapeutics specifically targets the non-functional part of DNA and aims to restore it. Mixing a DNA sequence with a calm, smiling symbol seemed like a powerful solution - transmitting the feeling of someone looking at you straight in the eyes, expressing a happier future."
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image {...data.image_3.childImageSharp} alt="" />
					<Image {...data.image_4.childImageSharp} alt="" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_2.childImageSharp}
					alt=""
				/>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image {...data.image_5.childImageSharp} alt="" />
						<Image {...data.image_10.childImageSharp} alt="" />
						<Image {...data.image_6.childImageSharp} alt="" />
					</div>

					<div className="column__text column__sticky text-content">
						<h3>
							Avrion's branding is a strong interpretation of its
							brand tension.{' '}
						</h3>
						<p>
							Simultaneously boldly smiling, but also truly
							pragmatic in its simplicity and visual approach,
							this brand revolves around humans and recovery.
						</p>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_8.childImageSharp}
					alt=""
				/>

				<Column>
					<div className="column__text column__sticky text-content">
						<h3>
							The simplicity of the symbol makes it not only
							instantly recognizable.{' '}
						</h3>
						<p>
							But also easy to integrate across different media
							contexts.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_7.childImageSharp}
							alt="Photo d'un médecin"
						/>
						<Image
							{...data.image_9.childImageSharp}
							alt="Photo d'une fiole"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_11.childImageSharp}
					alt=""
				/>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
